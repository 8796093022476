<template>
	<div v-if="companyId" class="company-nav">
		<NuxtLink :to="companyLink" class="link">
			<div class="company">{{ companyName ?? companyId }}</div>
			<div class="rie">{{ rieName ?? rieId }}</div>
			<div class="icon">
				<IconSvg icon="chevron-right" />
			</div>
		</NuxtLink>
	</div>
</template>
<script setup lang="ts">
	import {computed} from 'vue';
	import {companyRieOverview} from '~/lib/pageHelper';
	import IconSvg from '~/components/generic/IconSvg.vue';

	const props = defineProps({
		companyId: {
			type: Number,
			required: true
		},
		companyName: {
			type: String,
			required: false,
			default: undefined
		},
		rieId: {
			type: [String, Number],
			required: false,
			default: undefined
		},
		rieName: {
			type: String,
			required: false,
			default: undefined
		}
	});

	const companyLink = computed(() => companyRieOverview(props.companyId));
</script>

<style scoped>
	.company-nav {
		border-top: 1px solid #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
	}

	.link {
		padding: 10px 20px 10px 24px;
		text-decoration: none;
		color: inherit;

		display: grid;
		grid-template: "company icon" "rie icon" / 1fr auto;
	}

	.company {
		font-weight: bold;
		align-self: center;
		grid-area: company;
	}

	.rie {
		align-self: center;
		grid-area: rie;
	}

	.icon {
		grid-area: icon;
		align-self: center;
		justify-self: end;
	}
</style>
